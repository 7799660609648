import React from "react";
import { Grid, Typography } from "@mui/material";

const  NotFoundPage = () => {
  return (
    <Grid container>
      <Typography variant="h1">Page Not FOund</Typography>
    </Grid>
  );
}

export default NotFoundPage;
